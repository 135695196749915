import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
/**import React, { useState, useEffect } from 'react'; */
import testVertexShader from './shaders/test/vertex.glsl'
import testFragmentShader from './shaders/test/fragment.glsl'
import secondFragmentShader from './shaders/test/fragment2.glsl'


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scenes
const scene = new THREE.Scene()
const bufferScene = new THREE.Scene();

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const texture = textureLoader.load('/textures/2.jpg')
const dataTexture = createDataTexture()

// const dataTexture = createDataTexture();

/**
 * Render Buffers for Ping Pong
 */
// Create a new framebuffer we will use to render to
// the video card memory
let renderBufferA = new THREE.WebGLRenderTarget(
    sizes.width,
    sizes.height,
    {
        // In this demo UV coordinates are float values in the range of [0,1]. 
        // If you render these values into a 32bit RGBA buffer (a render target in format RGBA and type UnsignedByte), you will lose precision since you can only store 8 bit (256 possible integer values) per color channel. 
        // This loss is visible if you use the sampled uv coordinates for a texture fetch.
        // You can fix the issue if you add this parameter when creating the render target type: THREE.FloatType. 
        // The underlying texture is now a float texture that can hold your uv coordinates and retain precision.
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
        stencilBuffer: false
    }
)

let renderBufferB = new THREE.WebGLRenderTarget(
    sizes.width,
    sizes.height,
    {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
        stencilBuffer: false
    }
)

/**
 * Meshes
 */
// Geometry
const geometry = new THREE.PlaneBufferGeometry(2, 2);
const zoom = 0.3;

const resolution = new THREE.Vector3(sizes.width, sizes.height, window.devicePixelRatio)
// Buffer Material
const bufferMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uTexture: { value: dataTexture },
        uResolution: {
            value: resolution
        },
        uTime: { value: 0.0 },
        uMouse: { value: new THREE.Vector3() },
        uZoom: { value: zoom },
        uFrame: { value: 0 }
    },
    vertexShader: testVertexShader,
    fragmentShader: testFragmentShader,
});

const quadMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uTexture: { value: null },
        uResolution: {
            value: resolution
        },

        uTime: { value: 0.0 },
        uZoom: { value: zoom }
    },
    vertexShader: testVertexShader,
    fragmentShader: secondFragmentShader,
});

// Meshes
const bufferMesh = new THREE.Mesh(geometry, bufferMaterial);
bufferScene.add(bufferMesh)

const mesh = new THREE.Mesh(geometry, quadMaterial);
scene.add(mesh)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer()
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
document.body.appendChild(renderer.domElement);



renderer.domElement.addEventListener('mousedown', () => {
    bufferMaterial.uniforms.uMouse.value.z = 1;
})

renderer.domElement.addEventListener('mouseup', () => {
    bufferMaterial.uniforms.uMouse.value.z = 0;

})

renderer.domElement.addEventListener('mousemove', e => {
    //update uniforms
    bufferMaterial.uniforms.uMouse.value.x = e.clientX;
    bufferMaterial.uniforms.uMouse.value.y = sizes.height - e.clientY;

    console.log(bufferMaterial.uniforms.uMouse.value)
})



const onWindowResize = () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    // camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



    //update uniforms
    bufferMaterial.uniforms.uResolution.value.x = sizes.width
    bufferMaterial.uniforms.uResolution.value.y = sizes.height
}

window.addEventListener('resize', onWindowResize)

/**
 * Camera
 */
// Base camera
const camera = new THREE.OrthographicCamera(- 1, 1, 1, - 1, 0, 1);
// camera.position.set(0, 0, 1);
//scene.add(camera)
// bufferScene.add(camera)

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {

    const elapsedTime = clock.getElapsedTime()

    bufferMesh.material.uniforms.uTime.value = elapsedTime;
    bufferMesh.material.uniforms.uFrame.value += 1;
    // Explicitly set renderBufferA as the framebuffer to render to
    //the output of this rendering pass will be stored in the texture associated with FBO_B
    renderer.setRenderTarget(renderBufferA)
    // This will contain our ping-pong accumulated texture
    renderer.render(bufferScene, camera)

    //grab that texture and map it to the full screen quad
    //then draw the full sceen quad to the on screen buffer, ie, the display
    //mesh.material.map = renderBufferA.texture

    mesh.material.uniforms.uTexture.value = renderBufferA.texture;
    renderer.setRenderTarget(null)
    renderer.render(scene, camera);

    // 👇
    // Ping-pong our framebuffers by swapping them
    // at the end of each frame render
    //Now prepare for the next cycle by swapping FBO_A and FBO_B
    //so that the previous frame's *output* becomes the next frame's *input*
    const temp = renderBufferA
    renderBufferA = renderBufferB
    renderBufferB = temp
    bufferMaterial.uniforms.uTexture.value = renderBufferB.texture;

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

}

tick()


/**
 * CREATE RANDOM NOISE TEXTURE
 */

function createDataTexture() {

    // create a buffer with color data

    var size = sizes.width * sizes.height;
    var data = new Uint8Array(4 * size);


    for (var i = 0; i < size; i++) {

        var stride = i * 4;

        if (Math.random() < 0.1) {
            data[stride] = 255;
            data[stride + 1] = 255;
            data[stride + 2] = 255;
            data[stride + 3] = 255;
        }
        else {
            data[stride] = 0;
            data[stride + 1] = 0;
            data[stride + 2] = 0;
            data[stride + 3] = 255;
        }
    }


    // used the buffer to create a DataTexture

    console.log(data);
    var texture = new THREE.DataTexture(data, sizes.width, sizes.height, THREE.RGBAFormat);

    // just a weird thing that Three.js wants you to do after you set the data for the texture
    texture.needsUpdate = true;

    return texture;

}


/* function CurrentAge() {
    const [age, setAge] = useState(calculateAge());
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setAge(calculateAge());
      }, 1000 / 60);
  
      return () => clearInterval(intervalId);
    }, []);
  
    function calculateAge() {
      const birthDate = new Date(1995, 9, 5);
      const now = new Date();
      const diff = now.getTime() - birthDate.getTime();
      const ageDate = new Date(diff);
  
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
  
    return <div>My age is {age} years</div>;
  }
  
export default CurrentAge; */



// Container
const container2 = document.getElementById('threejs-container');
const sizes2 = {
  width: container2.clientWidth,
  height: container2.clientHeight
};

container2.style.borderRadius = '5px';


// Scenes
const scene2 = new THREE.Scene();
const bufferScene2 = new THREE.Scene();

// Textures
const dataTexture2 = createDataTexture2();

// Geometry
const geometry2 = new THREE.PlaneGeometry(2, 2);

// Resolution
const resolution2 = new THREE.Vector3(
  sizes2.width,
  sizes2.height,
  window.devicePixelRatio
);

// Render Buffers
let renderBufferA2 = createRenderBuffer2();
let renderBufferB2 = createRenderBuffer2();

// Buffer Material
const bufferMaterial2 = new THREE.ShaderMaterial({
    uniforms: {
        uTexture: { value: dataTexture2 },
        uResolution: { value: resolution2 },
    },
    vertexShader: document.getElementById("vertexShader").textContent,
    fragmentShader: document.getElementById("fragmentShaderBuffer").textContent
});

// Screen Material
const quadMaterial2 = new THREE.ShaderMaterial({
    uniforms: {
        uTexture: { value: null },
        uResolution: { value: resolution2 }
    },
    vertexShader: document.getElementById("vertexShader").textContent,
    fragmentShader: document.getElementById("fragmentShaderScreen").textContent
});

// Meshes
const mesh2 = new THREE.Mesh(geometry2, quadMaterial2);
scene2.add(mesh2);

const bufferMesh2 = new THREE.Mesh(geometry2, bufferMaterial2);
bufferScene2.add(bufferMesh2);

// Renderer
const renderer2 = new THREE.WebGLRenderer();
renderer2.setSize(sizes2.width, sizes2.height);
renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2));
container2.appendChild(renderer2.domElement);

// Camera
const camera2 = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

// Animate
const tick2 = () => {
    renderer2.setRenderTarget(renderBufferA2);
    renderer2.render(bufferScene2, camera2);
  
    mesh2.material.uniforms.uTexture.value = renderBufferA2.texture;
    renderer2.setRenderTarget(null);
    renderer2.render(scene2, camera2);

    const temp2 = renderBufferA2;
    renderBufferA2 = renderBufferB2;
    renderBufferB2 = temp2;
    bufferMaterial2.uniforms.uTexture.value = renderBufferB2.texture;

    window.requestAnimationFrame(tick2);
};

tick2();

// Window Resize
window.addEventListener('resize', onWindowResize2);

function onWindowResize2() {
    sizes2.width = container2.clientWidth;
    sizes2.height = container2.clientHeight;

    camera2.updateProjectionMatrix();
    renderer2.setSize(sizes2.width, sizes2.height);
    renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    quadMaterial2.uniforms.uResolution.value.x = sizes2.width;
    quadMaterial2.uniforms.uResolution.value.y = sizes2.height;
}

// Functions
function createDataTexture2() {
    var size2 = sizes2.width * sizes2.height;
    var data2 = new Uint8Array(4 * size2);

    for (var i = 0; i < size2; i++) {
        var stride2 = i * 4;
        var value2 = Math.random() < 0.5 ? 255 : 0;
        data2[stride2] = value2;
        data2[stride2 + 1] = value2;
        data2[stride2 + 2] = value2;
        data2[stride2 + 3] = 255;
    }

    var texture2 = new THREE.DataTexture(data2, sizes2.width, sizes2.height, THREE.RGBAFormat);
    texture2.needsUpdate = true;
    return texture2;
}

function createRenderBuffer2() {
    return new THREE.WebGLRenderTarget(
        sizes2.width,
        sizes2.height,
        {
            minFilter: THREE.NearestFilter,
            magFilter: THREE.NearestFilter,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            stencilBuffer: false
        }
    );
}



